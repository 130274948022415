<template>
  <div>
    <p
    class="team-members-functions"
      style="display: none"
      data-toggle="modal"
      ref="clickupdateInviteTeam"
      data-target="#updateUniform_Modal"
    >
      <img src="../../../../assets/Svg/team/team-options/edit-2.svg" alt="" />
    </p>

    <!-- UpdateUniformID -->
    <div
      class="modal fade"
      id="updateUniform_Modal"
      role="dialog"
      aria-labelledby="updateUniform_Modal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="updateUniform_ModalLabel">
              Invited Member.
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalUUpdateUniform"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="inning-btns">
              <button
                type="button"
                @click="deleteInvite(0)"
                :disabled="isLoadingArray[0]"
              >
                <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
                <span v-else>Delete</span>
              </button>
              <button
                type="button"
                @click="reinvite(1)"
                :disabled="isLoadingArray[1]"
              >
                <div v-if="isLoadingArray[1]" class="lds-dual-ring"></div>
                <span v-else>Re-invite</span>
              </button>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control message bg-white"
                id="fname"
                v-model="fname"
                @keyup="errorNull"
                required
              />
              <span class="placeholder">First Name</span>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control message bg-white"
                id="lname"
                v-model="lname"
                @keyup="errorNull"
                required
              />
              <span class="placeholder">Last Name</span>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control message bg-white"
                id="uniform"
                v-model="uniform"
                @keyup="errorNull"
                required
              />
              <span class="placeholder">Uniform No#</span>
            </div>
          </div>

          <div class="event-btns-wrapper px-3 pb-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              v-on:click="update(0)"
              :disabled="isLoadingArrayInvites[0]"
            >
              <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="(true, isLoadingArrayInvites[0])"
            >
              <div v-if="isLoadingArrayInvites[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- UpdateUniformID -->
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "InvitesTeamOption",
  components: {},
  data() {
    return {
      isDisabled: true,
      isLoadingArray: [],
      isLoadingArrayInvites: [],
      showLoader: 0,
      uniform: "",
      fname: "",
      lname: "",
      targetName: "",
      memberIOptions: false,
      items: [],
    };
  },

  mounted() {
    this.$root.$on("fetchInviteTeamRow", (item) => {
      this.items = item;
      this.fname = this.items.fname != "null" ? this.items.fname : "";
      this.uniform = this.items.uniform != "null" ? this.items.uniform : "";
      this.lname = this.items.lname != "null" ? this.items.lname : "";
      this.targetName = this.items.target;
      var elem = this.$refs.clickupdateInviteTeam;
      if (elem) {
        elem.click();
      }
    });
    $(".swal2-confirm").html("Yes");
  },
  methods: {
    // ---- Update ----------------------------------------------------------------------
    async update(index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArrayInvites, index, true);
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("id", this.items.id);
      formData.append("fname", this.fname);
      formData.append("lname", this.lname);
      formData.append("uniform", this.uniform);
      await axios
        .post(
          process.env.VUE_APP_API_URL + "invite/updateDataInvite",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.isDisabled = false;
          this.$set(this.isLoadingArrayInvites, index, false);
          this.showLoader = 0;
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.uniform = "";
          this.$alert("Information has been updated successfully.");
          var elem = this.$refs.closeModalUUpdateUniform;
          elem.click();
        });
    },

    // ---- Delete ----------------------------------------------------------------------
    async deleteInvite(index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("id", this.items.id);
      formData.append("target", this.items.target);
      await axios
        .post(process.env.VUE_APP_API_URL + "invite/deleteInvite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.isDisabled = false;
          this.$set(this.isLoadingArray, index, false);
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.uniform = "";
          this.$alert("Invitation has been deleted successfully.");
          var elem = this.$refs.closeModalUUpdateUniform;
          elem.click();
        });
      // setTimeout(() => {
      //   this.$set(this.isLoadingArray, index, false);
      // }, 2000);
    },

    // ---- Reinvite ----------------------------------------------------------------------
    async reinvite(index) {
      this.isDisabled = true;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("id", this.items.id);
      formData.append("target", this.items.target);
      await axios
        .post(process.env.VUE_APP_API_URL + "invite/reInvite", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$set(this.isLoadingArray, index, false);
          this.isDisabled = false;
          this.$root.$emit("HitInviteTeamList");
          this.fname = "";
          this.lname = "";
          this.uniform = "";
          this.$alert("Invitation has been re-sent successfully.");
          var elem = this.$refs.closeModalUUpdateUniform;
          elem.click();
        });
    },

    // ---- Check Null ----------------------------------------------------------------------
    errorNull: function () {
      if (this.uniform == "" && this.fname == "" && this.lname == "") {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
};
</script>
<style>
.inning-btns {
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inning-btns button {
  outline: none;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  background-color: #188ef5;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  padding: 9px 9px;
}

.inning-btns button:last-child {
  margin-right: 0;
}

.loaderSign {
  width: 45px !important;
  margin-top: 0px !important;
}
</style>
