<template>
  <div>

    <!-- If admin show eclipse -->
    <p class="team-members-functions" v-click-outside="onClickOutside" @click="openMembersOptions"
      v-if="currentIsAdmin == '1'">
      <img src="../../../../assets/Svg/ellipse.svg" alt="" />
    </p>

    <!-- If non-admin show eclipse on others member for direct message-->
    <p class="team-members-functions" v-click-outside="onClickOutside" @click="openMembersOptions"
      v-else-if="currentIsAdmin != '1' && items.id != currentUserId">
      <img src="../../../../assets/Svg/ellipse.svg" alt="" />
    </p>

    <p class="team-members-functions" v-click-outside="onClickOutside" @click="openMembersOptions"
      v-else-if="currentIsAdmin != '1' && items.id == currentUserId && items.fanRole != '1' && selectedRoomTeam.teamType == 'sports'">
      <img src="../../../../assets/Svg/ellipse.svg" alt="" />
    </p>

    <!-- If admin show eclipse -->
    <div class="sub-setting-options" v-if="memberOptions && currentIsAdmin == '1'">
      <ul class="all-options">
        <li v-if="items.id != currentUserId" @click="fetchDirectMessageData(items.username, items.c_id)">
          Send direct message
        </li>
        <li v-if="currentIsAdmin == '1' && items.fanRole != '1' && selectedRoomTeam.teamType == 'sports'"
          data-toggle="modal" data-target="#addUniform_Modal" @click="addUniformModal(items.id)">
          Player info
        </li>

        <li v-if="currentIsAdmin == '1' && items.adminRole != '1'" @click="makeAdmin(items.username, items.id)">
          Make team admin
        </li>
        <li v-if="currentIsAdmin == '1' && items.fanRole != '1'" @click="makeFan(items.username, items.id)">
          Make team fan
        </li>
        <li v-if="currentIsAdmin == '1' && items.adminRole != '0'" @click="makeMember(items.username, items.id)">
          Make team member
        </li>
        <div class="remove-sec">
          <li v-if="currentIsAdmin == '1'" @click="removeFan(items.username, items.id)">
            Remove as fan
          </li>
          <li v-if="currentIsAdmin == '1'" @click="removeAdmin(items.username, items.id)">
            Remove as team admin
          </li>
          <li v-if="currentIsAdmin == '1' && items.id != currentUserId" @click="removeMembers(items.username, items.id)">
            Remove from team
          </li>
        </div>
       
      </ul>
    </div>

    <!-- If non-admin and not itself show direct message-->
    <div class="sub-setting-options"
      v-else-if="memberOptions && currentIsAdmin != '1' && items.id != currentUserId && selectedRoomTeam.teamType == 'sports'">
      <ul>
        <li v-if="items.id != currentUserId" @click="fetchDirectMessageData(items.username, items.c_id)">
          Send Direct Message
        </li>
        <li v-if="currentIsAdmin == '1' && selectedRoomTeam.teamType == 'sports'" data-toggle="modal"
          data-target="#addUniform_Modal" @click="addUniformModal(items.id)">
          Player info
        </li>
      </ul>
    </div>

    <div class="sub-setting-options"
      v-else-if="memberOptions && currentIsAdmin != '1' && items.id != currentUserId && selectedRoomTeam.teamType != 'sports'">
      <ul>
        <li v-if="items.id != currentUserId" @click="fetchDirectMessageData(items.username, items.c_id)">
          Send Direct Message
        </li>
      </ul>
    </div>

    <div class="sub-setting-options"
      v-else-if="memberOptions && currentIsAdmin != '1' && items.id == currentUserId && selectedRoomTeam.teamType == 'sports'">
      <ul>
        <li data-toggle="modal" data-target="#addUniform_Modal" @click="addUniformModal(items.id)">
          Player info
        </li>

      </ul>
    </div>

    <div style="display:none" id="openModalClose" data-toggle="modal" data-target="#individualModalDirect">

    </div>

    <!-- Create Chat -->
    <DirectMessageModal :type="type" :userId="items.c_id" />
    <!-- Create chat modal -->

    <!-- UpdateUniformID -->
    <AddUniformModal :selectedRoomTeam="selectedRoomTeam" />
    <!-- UpdateUniformID -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import * as firestoreService from "../../../../database/firestore";
import $ from "jquery";
import DirectMessageModal from "../../../Teams/ChatRight/ChatSettingComponents/DirectMessageModal.vue";
import AddUniformModal from "../../../Teams/ChatRight/ChatSettingComponents/AddUniformModal";
import axios from "axios";
export default {
  name: "MemberOptions",
  components: {
    DirectMessageModal,
    AddUniformModal
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      memberOptions: false,
      roomId: this.selectedRoomTeam.roomId,
      sentUserName: "",
      sentUserId: "",
      type: "chat",
      Storage_URL: this.$userProfileStorageURL,
      currentUserName: localStorage.getItem("userName"),
      API_URL: this.$userProfileStorageURL,
      foundCurrentUser: [],
      blockUserFlag: 0,
    };
  },
  props: {
    items: {
      type: Object,
    },
    currentIsAdmin: {
      type: String,
    },
    currentUserId: {
      type: String,
    },
    selectedRoomTeam: {
      type: Object,
    },
    currentIsFan: {
      type: String
    },
  },

  mounted() {
    $(".swal2-confirm").html("Yes");
  },
  methods: {
    openMembersOptions() {
      this.memberOptions = !this.memberOptions;
    },

    onClickOutside() {
      this.memberOptions = false;
    },

    // ---- Remove Members ----------------------------------------------------------------------
    async removeMembers(name, removeUserId) {
  try {
    // Wait for confirmation
    await this.$confirm(
      `About to remove ${name} from the team. Continue?`,
      "Remove Member."
    );

    // Make API call to remove user
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}chat/removeTeamMember`,
      { roomId: this.roomId, userId: removeUserId }
    );

    if (response.data.statusCode === 200) {
      // Remove user from Firestore
      await firestoreService.removeRoomUser(this.roomId, removeUserId);

      // Remove user from local arrays using filter (more efficient)
      this.selectedRoomTeam.userRole = this.selectedRoomTeam.userRole.filter(
        (user) => user.id !== removeUserId
      );
      this.selectedRoomTeam.userCount = this.selectedRoomTeam.userCount.filter(
        (user) => user.id !== removeUserId
      );

      // Update Firestore with new local state
      await firestoreService.updateRoom(this.roomId, {
        userRole: this.selectedRoomTeam.userRole,
        userCount: this.selectedRoomTeam.userCount,
      });

      // Emit events to update UI
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeStatisticsReload");
      this.$root.$emit("gameTimeTeammatesReload");

      this.$notify({ type: "success", text: `${name} has been removed successfully.` });
    } else {
      console.error("Failed to remove member in chat system:", response.data);
      this.$notify({ type: "error", text: "Failed to remove member. Please try again." });
    }
  } catch (error) {
    console.error("Error removing member:", error);
    this.$notify({ type: "error", text: "Failed to remove member. Please try again." });
  }
},


    // ---- Make Admin ----------------------------------------------------------------------
    async makeAdmin(name, userID) {
  this.$confirm(`About to make ${name} admin of this team. Continue ?`, "Make Admin")
    .then(async () => {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      let updated = false;
      
      teamSUserRole.forEach((userRole, i) => {
        if (userRole.id == userID) {
          teamSUserRole[i].admin = "1";
          updated = true;
        }
      });
      
      if (!updated) {
        console.warn(`User ${userID} not found in userRole.`);
        return;
      }
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/handleMembersStatus`, {
          roomId: this.roomId,
          userId: userID,
          admin: "1",
        });
        
        if (response.data.statusCode === 200) {
          await firestoreService.updateRoom(this.roomId, { userRole: teamSUserRole });
        }
      } catch (error) {
        console.error("Error updating admin status:", error);
      }
      
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    });
},

async makeFan(name, userID) {
  this.$confirm(`About to make ${name} fan of this team. Continue ?`, "Make Fan")
    .then(async () => {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      teamSUserRole.forEach((userRole, i) => {
        if (userRole.id == userID) {
          teamSUserRole[i].fan = "1";
        }
      });
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/handleMembersStatus`, {
          roomId: this.roomId,
          userId: userID,
          fan: "1",
        });
        
        if (response.data.statusCode === 200) {
          await firestoreService.updateRoom(this.roomId, { userRole: teamSUserRole });
        }
      } catch (error) {
        console.error("Error updating fan status:", error);
      }
      
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    });
},

async removeFan(name, userID) {
  this.$confirm(`About to remove ${name} as fan of this team. Continue ?`, "Remove Fan")
    .then(async () => {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      teamSUserRole.forEach((userRole, i) => {
        if (userRole.id == userID) {
          teamSUserRole[i].fan = "0";
        }
      });
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/handleMembersStatus`, {
          roomId: this.roomId,
          userId: userID,
          fan: "0",
        });
        
        if (response.data.statusCode === 200) {
          await firestoreService.updateRoom(this.roomId, { userRole: teamSUserRole });
        }
      } catch (error) {
        console.error("Error removing fan status:", error);
      }
      
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    });
},

async removeAdmin(name, userID) {
  this.$confirm(`About to remove ${name} as admin of this team. Continue ?`, "Remove Admin")
    .then(async () => {
      const teamSUserRole = this.selectedRoomTeam.userRole;
      teamSUserRole.forEach((userRole, i) => {
        if (userRole.id == userID) {
          teamSUserRole[i].admin = "0";
        }
      });
      
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/handleMembersStatus`, {
          roomId: this.roomId,
          userId: userID,
          admin: "0",
        });
        
        if (response.data.statusCode === 200) {
          await firestoreService.updateRoom(this.roomId, { userRole: teamSUserRole });
        }
      } catch (error) {
        console.error("Error removing admin status:", error);
      }
      
      this.$root.$emit("gameTimeScheduledReload");
      this.$root.$emit("gameTimeTeammatesReload");
      this.$root.$emit("gameTimeStatisticsReload");
    });
},

    async makeMember(name, userID) {
  const teamSUserRole = this.selectedRoomTeam.userRole;

  const numberOfAdmins = teamSUserRole.filter(userRole => userRole.admin === "1").length;
  const currentUserIsOnlyAdmin =
    numberOfAdmins === 1 &&
    teamSUserRole.find(userRole => userRole.id === this.currentUserId && userRole.admin === "1");

  if (currentUserIsOnlyAdmin) {
    this.$alert("Make someone admin first");
    return;
  }

  this.$confirm(
    "About to make " + name + " normal user of this team. Continue ?",
    "Make Member"
  ).then(async () => {
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_URL}chat/handleMembersStatus`, {
        roomId: this.roomId,
        userId: userID,
        admin: "0",
        fan: "0",
      });

      if (response.data.statusCode === 200) {
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == userID) {
            teamSUserRole[i].admin = "0";
            teamSUserRole[i].fan = "0";
          }
          i++;
        }

        await firestoreService.updateRoom(this.roomId, { userRole: teamSUserRole });

        // Emit events
        this.$root.$emit("gameTimeScheduledReload");
        this.$root.$emit("gameTimeTeammatesReload");
        this.$root.$emit("gameTimeStatisticsReload");
      } else {
        console.error("Failed to update member status in chat system:", response.data);
      }
    } catch (error) {
      console.error("Error updating member status:", error);
    }
  });
},


  

    // ---- Remove from Admin ----------------------------------------------------------------------
    // async removeAdmin(name, userID) {
    //   this.$confirm(
    //     "About to remove " + name + " as admin of this team. Continue ?",
    //     "Remove Admin"
    //   ).then(() => {
    //     const teamSUserRole = this.selectedRoomTeam.userRole;
    //     var i = 0;
    //     for (const userRole of teamSUserRole) {
    //       if (userRole.id == userID) {
    //         teamSUserRole[i].admin = "0";
    //       }
    //       i++;
    //     }
    //     firestoreService.updateRoom(this.roomId, {
    //       userRole: teamSUserRole,
    //     });

    //     this.$root.$emit("gameTimeScheduledReload");
    //     this.$root.$emit("gameTimeTeammatesReload");
    //     this.$root.$emit("gameTimeStatisticsReload");
    //   });

    // },


    // ---- Send Message ----------------------------------------------------------------------
    fetchDirectMessageData(name, userID) {
      document.getElementById('openModalClose').click();
      this.$root.$emit("fetchDirectMessageData", name, userID);
    },

    // ---- Send Message ----------------------------------------------------------------------
    addUniformModal(userChatId) {
      this.$root.$emit("fetchUniformData", userChatId);
    },
  }
}

</script>
<style>


.loaderSign {
  width: 45px !important;
  margin-top: 0px !important;
}

.sub-setting-options {
  z-index: 1;
}

.sub-setting-options ul {
  display: block;
}
</style>